// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mr-phan-house-js": () => import("./../../../src/pages/mr-phan-house.js" /* webpackChunkName: "component---src-pages-mr-phan-house-js" */),
  "component---src-pages-mr-phan-house-truck-js": () => import("./../../../src/pages/mr-phan-house-truck.js" /* webpackChunkName: "component---src-pages-mr-phan-house-truck-js" */),
  "component---src-pages-take-away-js": () => import("./../../../src/pages/take-away.js" /* webpackChunkName: "component---src-pages-take-away-js" */),
  "component---src-pages-vorfor-vaffel-js": () => import("./../../../src/pages/vorfor-vaffel.js" /* webpackChunkName: "component---src-pages-vorfor-vaffel-js" */)
}

